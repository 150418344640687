// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.box-paycode {
  .paycode {
    height: 400px;
  }
}

#pay_container {
  .img {
    width: 140px;
    height: 140px;
    background: $hezi-background-gray;
  }

  .code-cuontdown {
    width: 120px;
    height: 36px;
    background: #fff4f4;
    border-radius: 120px;
  }

  .ant-statistic-content-value {
    font-size: 16px;
    color: #e93333;
  }

  .ant-statistic-content {
    text-align: center;
  }

  .recharge-options {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    div {
      margin: 10px;
      height: 80px;
      width: 140px;
      border: 1px solid #dddddd;
      border-radius: 4px;
      text-align: center;
      line-height: 80px;
      font-size: 24px;
      cursor: pointer;
    }

    .recharge-checked {
      background: #ecf2ff;
      border: 2px solid #1665ff;
      color: #1665ff;

      .t4 {
        color: #1665ff;
      }
    }
  }
}
