// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.box-search {
  width: 100%;
  height: 44px;
  position: relative;

  .search-icon {
    position: absolute;
    left: 20px;
    z-index: 1;
  }

  .search-input {
    min-width: 240px;
    width: 100%;
    height: 44px;
    background: $hezi-color-gray !important;
    border-radius: 22px;
    display: flex !important;
    padding-left: 40px;

    ::-webkit-input-placeholder {
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #BDBDBD;
      font-size: 14px;
    }
  }

  .ant-input {
    &:focus {
      border-color: #ccc;
    }
  }

  .search-dropdown {
    top: 50px !important;
    border: 1px solid $hezi-color-line;
    z-index: 101 !important;
  }

  .serach-subtitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .search-img {
    width: 40px;
    height: 40px;
    min-width: 40px;
    background: $hezi-background-gray;
    position: relative;
    overflow: hidden;
  }

  .ellipsis {
    text-overflow: ellipsis;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
  }
}