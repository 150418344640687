// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.MallTag {
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
    @media screen and (max-width: $phone1Width) {
        .MallTag-item-icon {
          display: none;
        }
      }

    // grid-template-rows: 16.6% 16.6% 16.6% 16.6% 16.6% 16.6%;
    .MallTag-item {
        height: 100px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 10%;
        cursor: pointer;
        margin-top: 20px;
        margin-bottom: 20px;

        .MallTag-item-icon {
            width: 64px;
            height: 64px;
            border-radius: 5px;
            // background-color: #f5f5f5;
        }

        .MallTag-item-title {
            font-family: PingFang SC-Semibold, PingFang SC;
            margin-left: 16px;
            font-size: 22px;
            color: #333;
            &:hover {
                color: #ff6a00;
            }
        }
    }
}