// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.mall {
  .mall-scroll {
    overflow-y: scroll !important;
    display: flex;
    justify-content: center;
  }

  .mall-main {
    padding: 0 !important;
  }

  @media only screen and (min-width: 1000px) {
    .mall-main {
      max-width: 1360px;
    }
  }

  @media only screen and (max-width: 1000px) and (min-width: 820px) {
    .mall-main {
      max-width: 1090px;
    }
  }

  @media only screen and (max-width: 820px) and (min-width: 620px) {
    .mall-main {
      max-width: 830px;
    }
  }

  @media only screen and (max-width: 620px) and (min-width: 450px) {
    .mall-main {
      max-width: 760px;
    }
  }

  @media only screen and (max-width: 450px) {
    .mall-main {
      // max-width: 600px;
      // min-width: 600px;
      min-width: 100vw;
      max-width: 100vw;
    }
  }

  .innerHtml-center {
    padding: 0 1% 0 2%;
  }

  @media only screen and (max-width: 820px) and (min-width: 500px) {
    .innerHtml-center {
      padding: 0 1% 0 5%;
    }
  }

  .innerHtml {
    max-width: 100%;
    overflow: scroll;
    font-size: 0;

    video {
      width: 100%;
      height: auto;
    }

    td,
    th {
      padding: 0;
      font-size: 0;
    }

    img {
      overflow-clip-margin: content-box;
      overflow: clip;
      display: inline-block;
    }

    table {
      border-spacing: 0;
    }

    p {
      margin: 0;
    }
  }
}