// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.ProductInformation {
    .information-header {
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
        height: 40px;
        font-size: 16px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 19px;
    }

    .col1 {
        min-width: 340px;
        flex: 1 1 auto;
    }

    .col2,
    .col3,
    .col4,
    .col5 {
        text-align: center;
        width: 80px;
        // flex: 1 1 auto;
    }
    .normal {
        font-weight: normal;
    }

    .information-main {
        .packages-box-img {
            width: 120px;
            height: 120px;
            border-radius: 4px;
            overflow: hidden;
            margin-right: 16px;
        }

        .packages-box-imgList {
            width: 120px;
            height: 120px;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            justify-content: space-between;
            overflow: hidden;
            flex: 0 0 120px;
            border-radius: 4px;
            overflow: hidden;
            margin-right: 16px;
        }

        .packages-box-imgList-img {
            width: 58px;
            height: 58px;
        }

        .packages-box-imgList-bg {
            width: 58px;
            height: 58px;
            background: #EFEFEF;
        }

        .skuinfo {
            display: flex;

            .sku-name {
                margin-top: 8px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 16px;
            }
        }

        .information-main-one {
            display: flex;
            justify-content: space-between;
            padding: 24px 0;
            font-size: 16px;
            font-family: PingFang SC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 19px;
        }

        .information-main-list {
            background: #F6F6F6;
            border-radius: 6px 6px 6px 6px;
            padding: 20px 24px;
            .information-main-list-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 28px;
                font-size: 14px;
            }
            .left {
                color: #000;
            }

            .right {
                color: #999999;
                display: flex;
            }
        }
    }
    .packages-box-content-spec-dingzhi {
        width: 108px;
        height: 32px;
        background: #FFF9E9;
        border-radius: 6px;
        border: 1px solid #FECE9F;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #E25E00;
        line-height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
        cursor: pointer;
      }
}