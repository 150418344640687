// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.order-detail {
  min-height: 100vh;
  background: $hezi-background-gray;
  .red {
    color: #FA2E2F;
  }
  .t-16 {
    font-size: 16px;
  }
  .t-18 {
    font-size: 18px;
  }
  .t-22 {
    font-size: 22px;
  }
  .blod {
    font-weight: bold;
  }
  .detail-left {
    width: 800px;
    .t5 {
      color: #999999;
    }

    .order-status {
      font-size: 16px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #D29827;
      line-height: 19px;
    }
    .card-title {
      width: 100%;
      // height: 35px;
      background-color: #fff;
      padding: 24px;
      border-radius: 8px;
      margin-bottom: 20px;
    }
    &-goods {
      width: 100%;
      background: #fff;
      .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // padding-top: 28px;
        // padding-bottom: 33px;
      }
    }
  }
}
