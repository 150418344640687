// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.box-datepicker {
  width: 100%;
  height: 44px;
  background: $hezi-color-gray !important;
  &-input {
    width: 100%;
    height: 100%;
    line-height: normal !important;
  }
  &-popover {
    width: 316px;
    .ant-picker-panel {
      width: 316px !important;
    }
    .ant-picker-date-panel {
      width: 100% !important;
    }
    .ant-picker-header {
      width: 100% !important;
    }
    .ant-picker-date-panel .ant-picker-content th {
      width: 42px !important;
    }
  }
}
