// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.box-goods-card {
  max-width: 100%;
  overflow: hidden;
  height: auto !important;

  .good-card-price {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #FA2E2F;
    font-size: 14px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 16px;

    .price-pre {
      font-size: 12px;
    }
  }
}

// @media only screen and (min-width: 768px) {
.good-card-cover {
  width: 248px;
  height: 248px;
  background: $hezi-background-gray;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  overflow: hidden;

  .productSingle-item-tag {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 56px;
    height: 30px;
  }

  .fit-img {
    max-width: 100%;
    overflow: hidden;
  }

  .productSingle-item-footer {
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    align-items: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: rgba(0, 0, 0, .1);
    display: flex;
    height: 48px;
    justify-content: space-between;
    position: absolute;
    // width: 220px;
    z-index: 9;

    .good-card-title {
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: #fff;
      display: -webkit-box;
      font-family: PingFangSC-Regular, PingFang SC;
      font-size: 18px;
      font-weight: 400;
      margin-left: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 138px;
    }

    .good-card-price {
      color: #fff;
      font-family: PingFangSC-Regular, PingFang SC;
      font-size: 18px;
      font-weight: 400;
      margin-right: 16px;
    }
  }
}

.box-goods-card {
  width: 248px;
  height: 307px;
}

.good-card-isTemplate {
  border: 1px solid #dddddd !important;
}


// }

// @media only screen and (max-width: 768px) {
//   .box-goods-card {
//     width: calc((100vw - 36px) / 3);
//     height: auto;
//   }

//   .good-card-cover {
//     width: calc((100vw - 36px) / 3);
//     border-radius: 6px;
//     height: calc((100vw - 36px) / 3);
//     background: $hezi-background-gray;
//     position: relative;
//     overflow: hidden;
//   }

//   .good-card-title {
//     font-size: 12px !important;
//     margin-top: 7px;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//   }

//   .good-card-subtitle {
//     display: none;
//   }
// }