// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.order {
  background: $hezi-color-gray;
  min-height: 100vh;

  &-content {
    width: 864px;
  }

  &-card {
    width: 100%;
    background: #fff;
    padding: 18px 24px;
    border-radius: 8px;
    .t5 {
      color: #999999;
    }

    .order-status {
      font-size: 16px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #D29827;
      line-height: 19px;
    }

    .card-title {
      width: 100%;
      height: 35px;
    }

    .card-main {
      border-top: 1px solid #F0F0F0;
      display: flex;
      justify-content: space-between;

      .card-right {
        width: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cart-right-info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          width: 150px;
          border-right: 1px solid #F0F0F0;
          min-height: 100px;
        }

        .cart-right-btns {
          width: 150px;
          display: flex;
          justify-content: center;
          align-content: center;

          div {
            width: 92px;
            height: 34px;
            background: #F6F6F6;
            border-radius: 17px 17px 17px 17px;
            line-height: 34px;
            vertical-align: middle;
            text-align: center;
          }
        }
      }

      .packages-box-img {
        width: 120px;
        height: 120px;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 16px;
      }

      .packages-box-imgList {
        width: 120px;
        height: 120px;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;
        overflow: hidden;
        flex: 0 0 120px;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 16px;
      }

      .packages-box-imgList-img {
        width: 58px;
        height: 58px;
      }

      .packages-box-imgList-bg {
        width: 58px;
        height: 58px;
        background: #EFEFEF;
      }
    }

    .card-footer {
      width: 100%;
      height: 52px;
    }
  }
}