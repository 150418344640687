// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.vipmark {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 20px;
  background: #090e1a;
  border-radius: 0px 8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 4px;
  z-index: 99;

  img {
    width: 8px;
    height: 10px;
    font-size: 12px;
    line-height: 17px;
  }

  &::after {
    content: '会员';
    font-size: 12px;
    line-height: 17px;
    background: linear-gradient(180deg, #ffebcb 0%, #ffcf86 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
