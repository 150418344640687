// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.MallTabs {
  overflow: scroll;
}


.mall-commodity {
  position: relative;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 270px;
  grid-auto-flow: row;
  // min-width: 1000PX;
  width: 100%;

  .mall-card-box {
    grid-column-start: 1;
    grid-column-end: 3;
    margin-right: 20px;
    max-width: calc(50% + 248px);

    .mall-card-img {
      width: 100%;
      height: calc(100% - 20px);
      border-radius: 10px;
      cursor: pointer;
      object-fit: fill;
      position: relative;
      max-height: 270px;
      left: 50%;
      top: calc(50% - 10px);
      transform: translate(-50%, -50%);
    }

    .mall-card-title {
      position: absolute;
      left: 48px;
      top: 100px;
      font-family: 'AlibabaPuHuiTi', sans-serif;
      font-size: 44px;
      z-index: 9;
    }

    .mall-card-more {
      position: absolute;
      left: 48px;
      top: 220px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-size: 18px;
      z-index: 9;
      color: #2d2e33 !important;
      font-weight: 400;
      margin-right: 10px
    }
  }


  .commodity {
    &-goods {
      width: 100%;
      scrollbar-width: none;
    }

    &-goods::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
    }
  }
}

@media only screen and (max-width: 500px){
  .mall-commodity {
    // grid-template-columns: 46% 46%;
    // padding-left: 4%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 100vw;
    .mall-card-box {
      max-width: calc(100vw - 20px);
      width: calc(100vw - 20px);
      padding-left: 10px;
      margin-right: 0 !important;
    }
  }
}

@media only screen and (max-width: 820px) and (min-width: 500px){
  .mall-commodity {
    grid-template-columns: 32% 32% 32%;
    margin-left: 5%;
  }
}
@media only screen and (max-width: 1000px) and (min-width: 820px) {
  .mall-commodity {
    grid-template-columns: 25% 25% 25% 25%;
    padding-left: 2%;
  }
}

@media only screen and (max-width: 1700px) and (min-width: 1000px) {
  .mall-commodity {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 270px 270px;
    grid-auto-flow: row;
    min-width: 1000PX;
    width: 100%;
    padding-left: 2%;
  }
}
@media only screen and (max-width: 2300px) and (min-width: 1700px) {
  .mall-commodity {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 260px 260px;
    grid-auto-flow: row;
    min-width: 1360PX;
    width: 100%;
    padding-left: 2%;
  }
}
@media only screen and (max-width: 2500px)  and (min-width: 2300px) {
  .mall-commodity {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 260px 260px;
    grid-auto-flow: row;
    min-width: 1600PX;
    width: 100%;
    padding-left: 2%;
  }
}
@media only screen and (min-width: 2500px) {
  .mall-commodity {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 260px 260px;
    grid-auto-flow: row;
    min-width: 1800PX;
    width: 100%;
    padding-left: 2%;
  }
}