// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.collocationPopup {
  width: 834px !important;
}
.header_top {
  width: 100%;
  height: 72px;
  line-height: 72px;
  padding-right: 28px;
  box-sizing: border-box;
  // background: url('../../assets/product/neida_top.png') no-repeat center
  // center;
  background-size: 100% 72px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.free_coll {
  position: relative;
  box-sizing: border-box;
  max-height: calc(100vh - 280px);
  overflow: scroll;
  padding-bottom: 100px;


  .main_con {
    height: calc(100vh - 280px - 92px);
  }

  .main_bottom {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    position: relative;

    .order-btn {
      font-size: 24px;
      position: absolute;
      right: 0;
      top: -10px;
      background-color: #fff;
      z-index: 99;
      padding: 0 30px 10px 14px;
      border-radius: 0 0 0 10px;

      &.active {
        color: #bf0008;
      }
    }

    .main_bottom_left {
      width: 160px;
      max-height: 100%;
      padding-bottom: calc(198px + constant(safe-area-inset-bottom));
      padding-bottom: calc(198px + env(safe-area-inset-bottom));
      box-sizing: border-box;
      padding-top: 32px;

      .main_left_li {
        font-size: 26px;
        line-height: 30px;
        width: 100%;
        padding: 24px 18px;
        box-sizing: border-box;
        color: #666;
        text-align: center;
        position: relative;

        .li_txt_desc {
          font-size: 20px;
        }

        .num {
          width: 24px;
          height: 24px;
          background: $hezi-color-primary;
          position: absolute;
          top: 6px;
          right: 6px;
          color: #fff;
          font-size: 16px;
          text-align: center;
          line-height: 24px;
          border-radius: 50%;
        }

        &.active {
          color: #333;
          background: $hezi-background-gray;
          font-weight: bold;

          &::before {
            content: ' ';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 7px;
            height: 48px;
            background-color: $hezi-color-primary;
            margin: auto;
          }
        }
      }
    }

    .main_bottom_right {
      max-height: 100%;
      padding-bottom: calc(198px + constant(safe-area-inset-bottom));
      padding-bottom: calc(198px + env(safe-area-inset-bottom));
      box-sizing: border-box;
      flex: 1;

      .main_bottom_right_next {
        width: 100%;
        height: 180px;
        text-align: center;
        overflow: hidden;
        padding-top: 4px;

        .next_icon {
          transform: rotateX(180deg);
          position: relative;

          .next_icon_xiahua {
            position: absolute;
            right: 2px;
            top: 4px;
          }
        }

        .loading {
          margin-top: 60px;
          animation: ro 2s linear infinite;
        }

        @keyframes ro {
          0% {
            transform: rotate(0deg);
          }

          50% {
            transform: rotate(180deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }

      .main_bottom_right_con {
        min-height: calc(100vh - 640px);
      }

      .main_right_li {
        display: flex;
        padding: 32px 0;
        box-sizing: border-box;
        position: relative;

        .checkbox {
          position: absolute;
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          color: #bf0008;
          right: 20px;
          bottom: 36px;
          border-radius: 50%;
          font-size: 20px;
          text-align: center;
          line-height: 40px;
          font-size: 50px;

          &.checkBox-border {
            border: 1.5px solid #bf0008;
          }
        }
      }

      .main_right_info {
        flex: 1;
        padding-right: 26px;
        box-sizing: border-box;
      }

      .main_right_three {
        width: 52px;
        height: 32px;
        background: #fff2f1;
        border-radius: 4px;
        text-align: center;
        line-height: 32px;
      }

      .main_right_img {
        width: 180px;
        height: 180px;
        border-radius: 12px;
        margin-right: 24px;
        margin-left: 24px;
        position: relative;

        &.member_card {
          &::after {
            content: '上海会员卡';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 96px;
            height: 28px;
            background: linear-gradient(270deg, #494949 0%, #6b6b6b 100%);
            border-radius: 0px 0px 8px 0px;
            color: #fbe1c4;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            box-sizing: border-box;
          }
        }
      }

      .main_right_title {
        width: 100%;
        line-height: 1.4;

        &.no-img {
          margin-left: 30px;
          height: 40px;
        }
      }

      .main_right_desc_con {
        width: 100%;
      }

      .main_right_info_con {
        width: 100%;
        min-height: 120px;
      }

      .main_right_desc {
        width: 100%;
        font-size: 20px;
        font-weight: 400;
        color: #a5a5a5;
        line-height: 30px;
      }

      .main_right_price {
        font-weight: 500;
        width: 100%;
        margin-top: 24px;
        color: $hezi-color-primary;
        font-size: 32px;
        line-height: 32px;
        position: relative;

        .p {
          font-size: 24px;
        }

        .old {
          font-size: 20px;
          color: #a5a5a5;
          text-decoration: line-through;
          font-weight: 400;
        }

        .main_right_price-left {
          width: 200px;
        }
      }
    }
  }

  .goods_btn {
    flex: none;
    width: 178px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #bf0008;
    border-radius: 40px;
    color: #fff;
    font-size: 28px;
    text-align: center;
    margin-right: 12px;
  }

  .main_con {
    background: #fff;
  }

  .good_num_box {
    position: absolute;
    right: 0;
    top: -4px;
    height: 40px;
    text-align: center;
    box-sizing: border-box;

    .good_nun_btn {
      height: 40px;
      width: 40px;
      font-size: 28px;
      vertical-align: top;
      display: inline-block;
      line-height: 40px;
      font-weight: bold;
      border-radius: 8px;
      // background: url(../../assets/product/icon39.png) no-repeat;
      background-size: 100% 100%;
      position: relative;

      &.add {
        // background: url(../../assets/product/icon38.png) no-repeat;
        background-size: 100% 100%;
      }

      &::before {
        content: '';
        position: absolute;
        top: -20px;
        left: -20px;
        bottom: -20px;
        right: -20px;
      }
    }

    .good_nun {
      height: 40px;
      min-width: 60px;
      line-height: 40px;
      font-size: 28px;
      display: inline-block;
      border: 1px solid #fff;
      vertical-align: top;
      padding: 0 10px;
      box-sizing: border-box;
      border-radius: 8px;
      color: #333;
    }
  }
}

.goodsCardfooter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 35px;
  padding-top: 10px;
  border-radius: 12px;

  .confirm {
    width: 360px;
    height: 56px;
    background: #FA2E2F;
    border-radius: 100px 100px 100px 100px;
    font-size: 18px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 21px;
    text-align: center;
    line-height: 56px;
  }
}