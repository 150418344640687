// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.category {
  overflow: hidden;

  .filter-dom {
    .ant-select-item-option {
      background: #ffffff;
    }
  }
}

.banner_img_box {
  width: 100%;
  height: 420PX;

  .banner_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.InfiniteScroll1 {
  padding: 0 14.5px !important;
}

.w-248 {
  width: 248px;
  height: 1px;
}

.filter-categary {
  font-size: 14px;
}

.filter-box {
  position: relative;
}

.right-arrow-box {
  position: absolute;
  right: 10px;
  top: 44px;
  cursor: pointer;
}

.filter {
  padding: 0 20px;
  margin: 16px 0;
  overflow: auto;
  margin-right: 40px;

  .filter-con {
    // padding-left: 200px;
    display: flex;
    justify-content: flex-start;
  }

  .tag-inactive {
    color: #73757d;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
    min-width: 130px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    flex: 1 0 auto;
  }

  .tag-active {
    color: #2d2e33;
    border-radius: 20px;
    border: 1px solid #2d2e33;
    padding: 14px 30px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .icon-current-box {
    position: absolute;
    z-index: 9;
    background-color: #fff;
    width: 80px;
    height: 40px;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);

    .icon-current {
      width: 20px;
      height: 20px;
    }

  }

  .tag-title {
    text-align: center;
    margin-left: 30px;
    margin-right: 30px;
  }

  .tag-line {
    font-size: 30px;
  }

  &-h {
    border-bottom: 1px solid $hezi-color-line;
    padding-bottom: 13px;
  }

  &-b {
    margin-top: 0;
  }

  &-m {
    padding: 0 12px;
  }

  &-input {
    width: 92px;
    height: 28px;
    background: $hezi-background-white;
    border-radius: 28px;
    border: 1px solid $hezi-color-line;
    position: relative;
  }

  &-dom {
    position: absolute;
    opacity: 0;
    width: 100%;

    &-dropdown {
      top: 40px !important;
      border: 1px solid $hezi-color-line;
    }
  }

  .button {
    min-width: 54px;
    margin-top: 14px;
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
    font-size: 18px;
    box-sizing: border-box;
  }
  &-three {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 20px 30px;
    &-item {
      margin-right: 20px;
      margin-bottom: 10px;
      font-size: 20px;
      color: #2d2e33;
      font-weight: 400;
      line-height: 20px;
      padding: 8px 14px;
      cursor: pointer;
      &:hover {
        color: #ff946b;
      }
      &-active {
        color: #ff946b;
        border: 1px solid #ff946b;
        border-radius: 20px;
        padding: 8px 14px;
      }
    }
  }
}

.filter-categary-icon {
  font-size: 12px;
  transform: scale(0.6);
}

.infinite-scroll-component {
  padding: 0 !important;
}