// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.TongTangFooter {
    background: rgb(244, 244, 244);

    .TongTangFooter-con {
        display: flex;
        justify-content: center;

    }

    .TongTangFooter-main {
        width: 100%;
        padding: 0 1% 0 2%;
        display: flex;
        justify-content: space-between;
    }

    @media only screen and (min-width: 1000px) {
        .TongTangFooter-main {
            max-width: 1360px;
        }
    }

    @media only screen and (max-width: 1000px) and (min-width: 820px) {
        .TongTangFooter-main {
            max-width: 1090px;
        }
    }

    @media only screen and (max-width: 820px) and (min-width: 620px) {
        .TongTangFooter-main {
            max-width: 830px;
        }
    }

    @media only screen and (max-width: 620px) and (min-width: 450px) {
        .TongTangFooter-main {
            max-width: 760px;
        }
    }

    @media only screen and (max-width: 450px) {
        .TongTangFooter-main {
            max-width: 600px;
        }
        &-scetion1 {
            flex-direction: column;
            .scetion1-right {
                justify-content: center !important;
            }
        }
    }

    @media only screen and (max-width: 820px) and (min-width: 500px) {
        .TongTangFooter-main {
            padding: 0 1% 0 5%;
        }
    }

    .label1 {
        color: #afb1b7;
        line-height: 24px;
    }

    .label2 {
        color: #73757d;
        line-height: 24px;
    }

    &-scetion1 {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 18px;
        margin-bottom: 18px;

        .scetion1-left {
            width: 49%;
            min-height: 200px;
            border-right: 1px solid rgb(227, 227, 227);
            padding-top: 20px;
            padding-bottom: 20px;

            img {
                width: 454px;
                height: 101px;
            }

            @media only screen and (max-width: 820px) and (min-width: 500px) {
                img {
                    width: 350px;
                    height: 78px;
                }
            }
        }

        .scetion1-right {
            height: calc(100% - 51%);
            min-height: 200px;
            display: flex;
            justify-content: flex-end;
            padding-top: 50px;

            .logo {
                width: 170px;
                height: 44px;
            }

            .official-account {
                &-img {
                    width: 112px;
                    height: 112px;
                }

                &-label {
                    margin-top: 20px;
                    color: #5a5b62;
                }
            }
        }
    }

    &-scetion2 {
        border-top: 1px solid rgb(227, 227, 227);
        display: flex;
        justify-content: center;
        color: #afb1b7;
        padding: 16px 0;
    }
}