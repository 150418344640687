// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.mall-banner {
  overflow-x: hidden !important;
  width: 100vw;

  .banner-one-img {
    width: 84vw;
    height: 30vw;
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .banner {
    &-advertise {
      background: $hezi-color-gray;
      // overflow: hidden;
      overflow: visible;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-box {
      // background: $hezi-color-gray;
      // overflow: hidden;
      overflow: visible;

      .imgbox {
        width: 100%;
        // height: 251PX;
        // overflow: hidden;
        overflow: visible;
        height: 100%;

      }

      img {
        width: 99%;
        height: 100%;
        object-fit: cover;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  // .heli-Carousel {
  //   overflow: visible;
  // }

  // .ant-carousel .slick-prev,
  // .ant-carousel .slick-prev:focus,
  // .ant-carousel .slick-prev:hover {
  //   left: 10px;
  //   z-index: 2;
  //   color: red;
  // }

  // .ant-carousel .slick-next,
  // .ant-carousel .slick-next:focus,
  // .ant-carousel .slick-next:hover {
  //   right: 10px;
  //   z-index: 2;
  //   color: white;
  // }

  // .ant-carousel .slick-list {
  //   overflow: visible;
  // }

  .ant-carousel .slick-active {}

  .ant-carousel .slick-active+.slick-slide {
    // padding-left: 10px;
    // padding-right: 10px;
  }

  // .ant-carousel .slick-slide {
  //   padding-left: 10px;
  //   padding-right: 10px;
  // }

  // .ant-carousel .slick-dots-bottom {
  //   bottom: 0;
  //   opacity: 0;
  // }

  .heli-Carousel {
    position: relative;
  }

  .leftButton,
  .rightButton {
    border: none;
    height: 54px;
    width: 54px;
    transition: 1s;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.6);
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 30px;
  }

  // .leftButton:hover,
  // .rightButton:hover {
  //   opacity: 1;
  // }
}