// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.good {
  min-height: 100vh;

  .InfiniteScroll {
    min-width: 700px;
  }
  .button-disable {
    cursor: not-allowed;
    background-color: rgb(243, 120, 120) !important;
  }

  .main {
    &-center {
      width: 550px;
      height: 550px;
      background: $hezi-background-gray;
      position: relative;
      overflow: hidden;
    }

    &-right {
      width: 392px;
      height: auto;

      .select {
        width: 392px;
        height: 52px;
        background: $hezi-color-gray;
      }

      .template {
        .item {
          width: 72px;
          height: 72px;
          background: $hezi-color-gray;
          overflow: hidden;
          position: relative;

          &.hot {
            border: 2px solid $hezi-text-color-light;
          }

          &-vipmark {
            transform: scale(0.8);
            right: -6px;
            top: -3px;
          }
        }

        p {
          width: 72px;
          margin: 0;
        }
      }

      .icon-disable {
        color: #cdcdcd;
      }
      .speci-sku-num-box {
        margin-top: 12px;
        display: flex;
        border: 1PX solid #DDDDDD;
        border-radius: 5px;
        height: 52px;
      }
      .speci-sku-btn {
        line-height: 48px;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        position: relative;
        margin-left: 16px;
        margin-right: 16px;
        &::before {
          content: '';
          position: absolute;
          top: -20px;
          left: -20px;
          bottom: -20px;
          right: -20px;
        }
        // &.disable {
        //   color: #cdcdcd;
        // }
      }
      .speci-sku-div {
        width: 100px;
        height: 48px !important;
        min-height: 48px !important;
        line-height: 48px;
        text-align: center;
        // border-radius: 8px;
        // background-color: $hezi-background-gray;
        border: 0px solid #DDDDDD;
        border-right: 1px solid #DDDDDD;
        border-left: 1px solid #DDDDDD;
        padding: 0;
        border-radius: 0;
      }
      .speci-sku-box-num {
        justify-content: space-between;
        &-bold {
          font-weight: bold;
        }
      }
    }
  }

  &-detail {
    background: $hezi-background-gray;
    width: 100%;
    height: auto;

    &-main {
      width: 840px;
      background: #fff;

      img {
        width: 100%;
        display: block;
      }
    }
  }
}
