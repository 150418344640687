// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.box-pay-card {
  width: 264px;
  height: auto;
  // max-height: 680px;
  overflow-y: scroll;
  background: #fff;

  .pay-button {
    height: 48px !important;
    line-height: 48px !important;
  }
}

.paybox {
  .vertical-align-sub {
    vertical-align: sub;
  }

  .icon-check {
    font-size: 20px;
    color: #e4e4e4;
  }

  .icon-checked {
    color: #1665ff;
  }

  .corporate-con {
    border: 1px solid #e4e4e4;
    border-radius: 6px;
    padding: 4px 2px 4px 6px;
    .corporate-item {
      display: flex;
      margin-bottom: 10px;

      .corporate-label {
        font-size: 14px;
        color: #333;
        width: 60px;
        min-width: 64px;
        // flex: 1 1 auto;
        text-align: left;
        margin-right: 6px;
      }

      .corporate-value {
        font-size: 14px;
        color: #666;
        white-space: wrap;
        word-break: break-all;
      }
    }
  }

}