// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.good {
  min-height: 100vh;

  .InfiniteScroll {
    min-width: 700px;
  }

  .select {
    width: 202px;
    height: 52px;
    background: $hezi-color-gray;
  }
  .main {
    &-center {
      width: 550px;
      height: 550px;
      background: $hezi-background-gray;
      position: relative;
      overflow: hidden;
    }

    &-right {
      width: 392px;
      height: auto;

      .template {
        .item {
          width: 72px;
          height: 72px;
          background: $hezi-color-gray;
          overflow: hidden;
          position: relative;

          &.hot {
            border: 2px solid $hezi-text-color-light;
          }

          &-vipmark {
            transform: scale(0.8);
            right: -6px;
            top: -3px;
          }
        }

        p {
          width: 72px;
          margin: 0;
        }
      }

    }
  }
  .icon-disable {
    color: #cdcdcd;
  }

  .speci-sku-num-box {
    margin-top: 12px;
    display: flex;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    height: 54px;
  }

  .speci-sku-btn {
    line-height: 48px;
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;

    &::before {
      content: '';
      position: absolute;
      top: -20px;
      left: -20px;
      bottom: -20px;
      right: -20px;
    }

    // &.disable {
    //   color: #cdcdcd;
    // }
  }

  .speci-sku-div {
    width: 100px;
    height: 48px !important;
    min-height: 48px !important;
    line-height: 48px;
    text-align: center;
    // border-radius: 8px;
    // background-color: $hezi-background-gray;
    border: 0px solid #DDDDDD;
    border-right: 1px solid #DDDDDD;
    border-left: 1px solid #DDDDDD;
    padding: 0;
    border-radius: 0;
  }

  .speci-sku-box-num {
    justify-content: space-between;

    &-bold {
      font-weight: bold;
    }
  }

  .package-inside {
    width: 840px;
    background: #fff;
    border-radius: 8px;
    .package-outer-arr {
      padding-bottom: 28px;
      border-bottom: 1px solid #F0F0F0;
      margin-bottom: 28px;
    }
    .package-outer-arr-num {
      color: #999999;
    }
    .package-inside-head {
      justify-content: space-between;
    }

    .package-inside-item {
      width: 100%;
      // height: 80px;
      justify-content: space-between;

      .package-inside-item-img {
        width: 80px;
        height: 80px;
        border-radius: 12px;
      }

      .package-inside-item-title {
        justify-content: space-between;
      }

      .package-inside-item-con {
        width: 500px;

        &.active {
          width: 420px;
        }
      }

      .package-inside-item-btn {
        height: 40px;
        width: 40px;
        font-size: 28px;
        vertical-align: top;
        display: inline-block;
        line-height: 40px;
        font-weight: bold;
        border-radius: 8px;
        // background: url(../../assets/product/icon39.png) no-repeat;
        background-size: 100% 100%;
        position: relative;

        &.add {
          // background: url(../../assets/product/icon38.png) no-repeat;
          background-size: 100% 100%;
        }

        &::before {
          content: '';
          position: absolute;
          top: -20px;
          left: -20px;
          bottom: -20px;
          right: -20px;
        }
      }

      .package-inside-item-btm {
        justify-content: center;
        align-items: center;
      }

      .package-inside-item-num {
        width: 40px;
        text-align: center;
      }

      .span-primary {
        color: $hezi-color-primary;
      }
      .package-inside-item-spec_info {
        color: #999999;
      }

    }

    .package-inside-item-inside {
      font-size: 24px;
      color: $hezi-color-primary;
      font-weight: bold;
      border: 1px solid $hezi-color-primary;
      width: 200px;
      height: 52px;
      line-height: 52px;
      border-radius: 100px 100px 100px 100px;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }

  &-detail {
    background: $hezi-background-gray;
    width: 100%;
    height: auto;

    &-main {
      width: 840px;
      background: #fff;

      img {
        width: 100%;
        display: block;
      }
    }
  }
}