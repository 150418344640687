// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.box-loginWrapper {
  position: relative;

  &-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.box-loginModal {
  overflow: hidden;
  position: relative;
  height: 370px;

  .box-mobile {
    width: 100%;
    position: absolute;
    min-height: 310px;

    &.hide {
      left: -460px;
    }

    &-form {
      width: 100%;
      height: 56px;
      border: 1px solid $hezi-color-line !important;
      font-size: 16px !important;
      border-radius: 4px !important;
      font-weight: 400;
    }

    &-input {
      width: 200px;
    }

    &-code {
      width: 100px;
    }

    &-agreement {
      margin-top: 10px;
      font-size: 18px;
      color: #bdbdbd;

      .agreement {
        margin-left: 10px;

        a {
          color: #ff6e00;
        }
      }
    }

    .button {
      height: 56px;
      line-height: 56px;
      border-radius: 28px;
      font-size: 18px;
    }

    .code-cuontdown {
      margin-top: -3px;

      span {
        font-size: 14px;
        color: #bdbdbd;
      }
    }
  }

  .box-wechat {
    width: 360px;
    position: absolute;

    &.hide {
      left: -360px;
    }

    .wechat-code {
      margin-top: -16px;
      width: 300px;
      height: 300px;
      transform: scale(0.8);
      background: $hezi-background-gray;
      overflow: hidden;
    }
  }
}