// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.category {
  overflow: hidden;

  .filter-dom {
    .ant-select-item-option {
      background: #ffffff;
    }
  }
}

.InfiniteScroll1 {
  padding: 0 14.5px !important;
}

.w-248 {
  width: 248px;
  height: 1px;
}

.filter-categary {
  font-size: 14px;
}


.filter {
  padding: 0 20px;
  margin: 16px 0;

  &-h {
    border-bottom: 1px solid $hezi-color-line;
    padding-bottom: 13px;
  }

  &-b {
    margin-top: 0;
  }

  &-m {
    padding: 0 12px;
  }

  &-input {
    width: 92px;
    height: 28px;
    background: $hezi-background-white;
    border-radius: 28px;
    border: 1px solid $hezi-color-line;
    position: relative;
  }

  &-dom {
    position: absolute;
    opacity: 0;
    width: 100%;

    &-dropdown {
      top: 40px !important;
      border: 1px solid $hezi-color-line;
    }
  }

  .button {
    min-width: 54px;
    margin-top: 14px;
    line-height: 40px;
    height: 40px;
    padding: 0 10px;
    font-size: 18px;
    box-sizing: border-box;
  }
}

.filter-categary-icon {
  font-size: 12px;
  transform: scale(0.6);
}

.infinite-scroll-component {
  padding: 0 !important;
}