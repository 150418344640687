// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.skuImg {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 20px;
}

.speci {
  border-radius: 24px 24px 0 0;
  background: #fff;
  position: relative;
  // min-height: calc(100vh - 620px);
  height: calc(100% - 300px);
  overflow-y: scroll;


  .speci-scroll-div1 {
    width: 100%;
    min-height: calc(100vh - 620px);
    height: calc(100vh - 620px);
    overflow: scroll;
    margin-bottom: 100px;
  }

  .speci-good-info {
    .speci-good-info-img {
      width: 180px;
      height: 180px;
      margin-right: 28px;
      overflow: hidden;
      border-radius: 12px;

      .speci-good-info-imgs {
        width: 200px;
        height: 200px;
      }
    }

    .speci-good-desc {
      .speci-good-price {
        font-size: 40px;
        font-weight: 500;

        .speci-good-old-price {
          font-weight: 400;
          text-decoration: line-through;
        }
      }

      .speci-sku-name {
        margin-top: 20px;
        line-height: 1.4;
        width: 458px;
      }
    }
  }

  // .speci-sku-box {
  //   .speci-sku-box-attr {
  //     font-weight: bold;
  //   }

  //   .speci-sku-item {
  //     line-height: 36px;
  //     display: inline-block;
  //     border-radius: 28px;
  //     border: 2px solid $hezi-background-gray;
  //     background-color: $hezi-background-gray;
  //     font-weight: 400;

  //     &.action {
  //       border-color: $hezi-color-primary;
  //       color: $hezi-color-primary;
  //       background-color: $hezi-color-primary-100;
  //     }

  //     &.disable {
  //       color: $hezi-text-color-gray;
  //     }

  //     &.nostock {
  //       color: #cdcdcd;
  //       border: 2px dotted #ddd;
  //       position: relative;
  //       background: #fff;

  //       &::before {
  //         content: ' ';
  //         display: block;
  //         width: 82px;
  //         height: 32px;
  //         // background: url('../../assets/product/quehuo@2x.png') no-repeat;
  //         background-size: 82px 32px;
  //         position: absolute;
  //         top: -25px;
  //         right: -10px;
  //       }
  //     }
  //   }
  // }

  // .speci-sku-box-num {
  //   justify-content: space-between;

  //   &-bold {
  //     font-weight: bold;
  //   }
  // }

  // .speci-sku-num-box {
  //   height: 72px;
  //   margin-top: 40px;
  //   display: flex;
  // }

  // .speci-sku-btn {
  //   line-height: 48px;
  //   text-align: center;
  //   font-size: 28px;
  //   font-weight: bold;
  //   position: relative;

  //   &::before {
  //     content: '';
  //     position: absolute;
  //     top: -20px;
  //     left: -20px;
  //     bottom: -20px;
  //     right: -20px;
  //   }

  //   // &.disable {
  //   //   color: #cdcdcd;
  //   // }
  // }

  // .icon-disable {
  //   color: #cdcdcd;
  // }

  // .speci-sku-view {
  //   width: 100px;
  //   height: 48px !important;
  //   min-height: 48px !important;
  //   line-height: 72px;
  //   text-align: center;
  //   border-radius: 8px;
  //   background-color: $hezi-background-gray;
  // }

  // .speci-footer-btn {
  //   width: 678px;
  //   position: absolute;
  //   bottom: 40px;
  //   right: 0;
  //   left: 0;
  //   margin: auto;
  // }
}

.speci-active {
  border-radius: 24px 24px 0 0;
  background: #fff;
  // height: calc(100vh - 620px);
  position: relative;
}

.speci-hot {
  border-radius: 24px 24px 0 0;
  background: #fff;
  position: relative;
}