// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.box-header {
  width: 100%;
  height: 72px;
  border-bottom: 1px solid $hezi-color-line;
  position: fixed;
  top: 0;
  background: $hezi-background-white;

  @media screen and (max-width: $phone1Width) {
    .classify-all-text,
    .header-logo {
      display: none;
    }
  }
  .header-logo {
    min-width: 58px;
    height: 68px;

    img {
      margin-top: 9px;
      height: 50px;
    }
  }

  // @media only screen and (max-width: 1280px) {
  //   .header-navigate {
  //     justify-content: space-between;
  //     width: 100%;
  //     .mr-32 {
  //       margin-right: 0;
  //     }
  //   }
  // }
  .header-navigate {
    .t4 {
      line-height: 28px;
    }

    .viptab {
      left: 284px;
      background: linear-gradient(90deg, #121622 3.12%, #070c18 100%);
      border-radius: 14px;
      font-size: 13px;
      line-height: 18px;
      padding: 5px 12px;
      color: #ffc366;
      cursor: pointer;

      &::after {
        // 生成的光影渐变背景
        content: attr(data-text);
        z-index: 5;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(120deg,
            transparent,
            #121622 18%,
            rgba(#f8e5c8, 0.5) 20%,
            #121622 22%,
            #121622 38%,
            rgba(#f8e5c8, 0.5) 40%,
            #121622 42%,
            #121622 68%,
            rgba(#f8e5c8, 0.5) 70%,
            #121622 72%,
            #121622 88%,
            rgba(#f8e5c8, 0.5) 90%,
            #121622 92%);
        background-clip: text;
        background-size: 250% 100%;
        animation: shine 10s infinite linear;
      }

      @keyframes shine {
        0% {
          background-position: 0 0;
        }

        100% {
          background-position: -100% 0;
        }
      }
    }
  }

  .header-avatar {
    width: 36px;
    height: 36px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .header-cart {
    position: relative;
  }

  .icon-gouwuche {
    font-size: 34px;
  }

  .cart-num {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $hezi-text-color-light;
    font-size: 12px;
    color: #fff;
    position: absolute;
    right: -0px;
    top: -0px;
    // transform: scale(0.6);
  }

}

.header-classify {
  .ant-popover-inner {
    padding: 0 !important;
    background: none !important;
  }
}

.classify-all {
  color: #2d2e33;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;

  .classify-left {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
}

.classify-hover {
  color: #ff946b !important;
}


.renderClassify {
  display: flex;
  justify-content: flex-start;
  box-shadow: 0 20px 50px 0 hsla(0, 0%, 51%, .17);

  &-one-box {
    background: hsla(0, 0%, 100%, .1);
    backdrop-filter: blur(100px);
  }

  &-one {
    width: 200px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 24px;
    background: #f4f4f4;
    opacity: .9;
    height: 100%;

    .one-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 52px;
      margin-bottom: 10px;
      margin-left: 20px;
      // width: 164px;

      .one-icon {
        align-items: center;
        display: flex;
        font-size: 20px;
        height: 26px;
        justify-content: center;
        margin-left: 24px;
        width: 26px;
      }

      .one-text {
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 16px;
        font-weight: 400;
        margin-left: 20px;
        color: #73757d;
      }
    }

    .one-item-active {
      border-radius: 21px 0 0 21px;
      background: #fff;

      .one-icon {}

      .one-text {
        color: #2d2e33;
      }
    }

  }

  &-right-box {
    background: hsla(0, 0%, 100%, .1);
    backdrop-filter: blur(50px);
  }

  &-right {
    min-width: 300px;
    padding: 20px;
    backdrop-filter: blur(10px);
    background: #fff;
    opacity: .8;
    height: 100%;

    .right-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 20px;
      min-height: 60px;

      .right-two {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .right-two-line {
        background: #ff946b;
        height: 14px;
        margin-right: 3px;
        width: 2px;
      }

      .right-two-text {
        color: #2d2e33;
        font-family: PingFangSC-Regular, PingFang SC;
        font-size: 16px;
        font-weight: 400;
        max-width: 148px;
        width: 148px;
        text-overflow: wrap;
        margin-right: 20px;
      }

      .right-three {
        width: 600px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: 1px dashed #73757d;
        margin-right: 20px;
        margin-top: 20px;

        .right-three-text {
          color: #73757d;
          cursor: pointer;
          font-family: PingFangSC-Regular, PingFang SC;
          font-size: 16px;
          font-weight: 400;
          margin-right: 30px;
          padding: 0 0 20px;
        }
      }
    }
  }
}

.box-header-popover {
  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .vipicon {
      width: 21px !important;
      margin-left: 4px;
    }

    .novip {
      font-weight: 400;
      font-size: 11px;
      line-height: 15px;
      color: #999999;

      .tovip {
        color: #1665ff;
      }
    }
  }
}

.ant-popover-inner-content {
  padding: 0;
}

@media only screen and (min-width: 768px) {
  .box-header {

    .header-left,
    .header-right {
      width: 60%;
    }
  }

  .box-header-popover {
    width: 240px;

    .purse {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 14px;

      .price {
        font-weight: 800;
      }
    }

    .topup {
      padding: 2px 10px;
      border: 1px solid #1665ff;
      border-radius: 20px;
      color: #1665ff;
    }
  }
}

@media only screen and (max-width: 768px) {
  .mbox-header-popover {
    width: 100px;
  }

  .ant-popover-arrow {
    display: none;
  }
}