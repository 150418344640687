// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.box-goods-num {
  .ant-popover-arrow {
    display: none !important;
  }
}
.box-num-popover {
  height: 272px;
  .num-input {
    width: 100% !important;
    height: 40px;
    border: 1px solid $hezi-color-line;
    overflow: hidden;
    &-content {
      height: 40px !important;
      width: 120%;
    }
  }
  .num-content {
    max-height: 200px;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .num-content::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }
}
