// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.box-order-card {
  .opacity {
    opacity: 0.3;
  }

  .card-name {
    max-width: 260px;
  }

  .card-img {
    position: relative;
    background: $hezi-background-gray;
    overflow: hidden;

    &-invalid {
      width: 48px;
      height: 48px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      color: #fff !important;
    }

    &.sm {
      width: 60px;
      height: 60px;
    }

    &.md {
      width: 72px;
      height: 72px;
    }

    &.lg {
      width: 100px;
      height: 100px;
    }
  }

  .card-num {
    width: 108px;
    height: 40px;
    border: 1px solid $hezi-color-line;
    margin-top: -8px;

    .rotate {
      transform: rotate(90deg);
      margin-top: -2px;
    }
  }

  .icon-disable {
    color: #cdcdcd;
  }
  .speci-sku-num-box {
    margin-top: 12px;
    display: flex;
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    height: 46px;

    .speci-sku-btn {
      line-height: 40px;
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      position: relative;
      margin-left: 14px;
      margin-right: 14px;

      &::before {
        content: '';
        position: absolute;
        top: -20px;
        left: -20px;
        bottom: -20px;
        right: -20px;
      }
    }

    .speci-sku-div {
      width: 64px;
      height: 44px !important;
      min-height: 44px !important;
      line-height: 44px;
      text-align: center;
      border: 0px solid #DDDDDD;
      border-right: 1px solid #DDDDDD;
      border-left: 1px solid #DDDDDD;
      padding: 0;
      border-radius: 0;
    }
  }

  .packages-box-content-spec-dingzhi {
    width: 108px;
    height: 42px;
    background: #FFF9E9;
    border-radius: 6px;
    border: 1px solid #FECE9F;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #E25E00;
    line-height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    cursor: pointer;
  }

}