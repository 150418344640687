// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.product-info {
  background: #fff;
  box-sizing: border-box;
  &.pb {
    padding-bottom: 30px;
  }

  .product-info-price {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4;
    color: #FA2E2F;
  }

  // .font-18{
  //   font-size: 18px;
  // }

  .product-info-desc {
    text-decoration: line-through;
  }

  .product-info-title-mt {
    margin-top: 26px;
  }
}
