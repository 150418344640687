// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

@font-face {
  font-family: 'iconfont';
  src: url('https://baiyin-produce.oss-cn-hangzhou.aliyuncs.com/js-view-newBox-h5/iconfont/iconfont.woff2') format('woff2'),
    url('https://baiyin-produce.oss-cn-hangzhou.aliyuncs.com/js-view-newBox-h5/iconfont/iconfont.woff') format('woff'),
    url('https://baiyin-produce.oss-cn-hangzhou.aliyuncs.com/js-view-newBox-h5/iconfont/iconfont.ttf') format('truetype');
}

@font-face {
  font-family: 'AlibabaPuHuiTi';
  src: url('https://baiyin-produce.oss-cn-hangzhou.aliyuncs.com/js-view-c-h5/font-family/AlibabaPuHuiTi-3-65-Medium.woff2') format('woff2'),
       url('https://baiyin-produce.oss-cn-hangzhou.aliyuncs.com/js-view-c-h5/font-family/AlibabaPuHuiTi-3-65-Medium.woff') format('woff');
  /* 可以根据需要添加其他字体格式，比如ttf等 */
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  padding: 0;
  line-height: normal;
  font-style: normal;
  // 兼容pad的cursor：pointer 点击出现蓝色背景问题
  -webkit-tap-highlight-color: transparent;
}

*::-webkit-scrollbar {
  display: none;
}

html {
  /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
  -ms-overflow-style: none;
  /*火狐下隐藏滚动条*/
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  line-height: normal;
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 28px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wode:before {
  content: "\e611";
}

.icon-icon_select:before {
  content: "\e628";
}

.icon-sucaiku:before {
  content: "\e6b6";
}

.icon-Vector:before {
  content: "\e6ba";
}

.icon-licai:before {
  content: "\e601";
}

.icon-weixin:before {
  content: "\e637";
}

.icon-edit:before {
  content: "\e678";
}

.icon-edit11:before {
  content: "\e6b0";
}

.icon-bianji:before {
  content: "\e610";
}

.icon-gengduo:before {
  content: "\e719";
}

.icon-shanchutianchong:before {
  content: "\e68c";
}

.icon-chexiao-2:before {
  content: "\e6aa";
}

.icon-weixinzhifu:before {
  content: "\e689";
}

.icon-zhifu-zhifubao:before {
  content: "\e6bd";
}

.icon-inbox-full:before {
  content: "\e897";
}

.icon-biaodiandidian:before {
  content: "\e65e";
}

.icon-bianji:before {
  content: "\e610";
}

.icon-a-xiajiantouzhixiangxiajiantou:before {
  content: "\e60d";
}

.icon-yuyinguanbi:before {
  content: "\e903";
}

.icon-pintu:before {
  content: "\e61e";
}

.icon-gengduoneirong:before {
  content: "\e600";
}

.icon-delete:before {
  content: "\e654";
}

.icon-text:before {
  content: "\e6a2";
}

.icon-img:before {
  content: "\e6a1";
}

.icon-chexiao:before {
  content: "\e6ae";
}

.icon-save:before {
  content: "\e6b9";
}

.icon-kefu:before {
  content: "\e608";
}

.icon-xuanze:before {
  content: "\e623";
}

.icon-dingdan:before {
  content: "\e639";
}

.icon-gouwuche:before {
  content: "\e6c2";
}

.icon-zhankai1:before {
  content: "\e667";
}

.icon-xuanzhong:before {
  content: "\e60a";
}

.icon-arrow:before {
  content: "\e647";
}

.icon-search:before {
  content: "\e653";
}


// 字体大小
.t1 {
  font-size: 24px;
  color: $hezi-text-color;
}

.t2 {
  font-size: 20px;
  color: $hezi-text-color;
}

.t3 {
  font-size: 16px;
  color: $hezi-text-color;
}

.t4 {
  font-size: 14px;
  color: $hezi-text-color;
}

.t5 {
  font-size: 12px;
  color: $hezi-text-color;
}

.t6 {
  font-size: 18px;
  color: $hezi-text-color;
}

// 文字颜色
.text-primary {
  color: $hezi-text-color;
}

.text-primary500 {
  color: $hezi-text-color-500;
}

.text-light {
  color: $hezi-text-color-light;
}

.text-gray {
  color: $hezi-text-color-gray;
}
.text-gray600 {
  color: #a5a5a5;
}

.text-white {
  color: #fff;
}

.text-icon-gray {
  color: $hezi-gray-icon;
}

.text-icon-light {
  color: $hezi-text-color-light;
}

.text-icon-gray500 {
  color: $hezi-color-gray-500;
}

.text-red {
  color: #e93333;
}

.text-green {
  color: #45b51e;
}

.text-wechat {
  color: #09bb07;
}

.text-alipay {
  color: #04a9f1;
}

// 透明度
.opacity-7 {
  opacity: 0.7;
}

.opacity-3 {
  opacity: 0.3;
}

// 字重
.bold {
  font-weight: bold;
}

.font-normal {
  font-weight: normal;
}

// 背景色
.bg-gray {
  background: $hezi-background-gray;
}

.bg-white {
  background: $hezi-background-white;
}

// 投影
.shadow {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.04);
}

.shadow-primary {
  box-shadow: 0 4px 16px 0px rgba($color: $hezi-text-color, $alpha: 0.06);
}

// 按钮
.button-group {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    flex: none;

    &:last-child {
      margin-right: 0;
    }
  }

  &.full {
    width: 100%;
    padding: 0 48px;
  }
}

.button {
  margin: 0;
}

.button,
button.button {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  text-align: center;
  background-color: transparent;
  border: none;
  appearance: none;
  outline: none;
  border-radius: 18px;
  font-size: 12px;
  transition: opacity 0.3s;
  padding: 0 14px;
  height: 36px;
  line-height: 36px;
  min-width: 80px;
  cursor: pointer;

  &.button-sm {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    min-width: 92px;
    padding: 0 14px;
  }

  // &.button-md {
  //   height: 80px;
  //   line-height: 80px;
  //   font-size: 28px;
  // }

  &.button-lg {
    height: 52px;
    line-height: 52px;
    font-size: 16px;
    padding: 0 48px;
    border-radius: 120px;
  }

  &.square {
    border-radius: 0;
  }

  &.row {
    width: 100%;
  }

  &.button-plain {
    background-color: rgba(250, 46, 47, 0.08);
    border: 1px solid $hezi-color-primary;
    color: $hezi-color-primary;
  }

  &.button-plain-white {
    background-color: $hezi-background-white;
    border: 1px solid $hezi-color-primary;
    color: $hezi-color-primary;
  }

  &.button-primary {
    background: $hezi-color-primary;
    color: $hezi-text-white;
  }

  &.button-gray {
    background: $hezi-color-gray;
    color: $hezi-text-color;
  }

  &.button-plain-gray {
    background-color: $hezi-background-white;
    border: 1px solid $hezi-color-gray-100;
    color: $hezi-text-color;
  }

  &.button-light {
    background-color: $hezi-background-plain;
    border: 1px solid $hezi-color-primary;
    color: $hezi-color-primary;
  }

  &.disabled {
    @extend .button-gray;
    color: $hezi-text-color-gray;
  }
}

button:after {
  content: none;
  border: none;
}

.card {
  background-color: $hezi-background-white;
  display: inline-block;
  zoom: 1;
  box-sizing: border-box;

  @extend .round-md;
}

// 间距
@for $distance from 1 through 40 {
  .p-#{$distance*2} {
    padding: $distance * 2px;
  }

  .px-#{$distance*2} {
    padding-left: $distance * 2px;
    padding-right: $distance * 2px;
  }

  .py-#{$distance*2} {
    padding-top: $distance * 2px;
    padding-bottom: $distance * 2px;
  }

  .pt-#{$distance*2} {
    padding-top: $distance * 2px;
  }

  .pb-#{$distance*2} {
    padding-bottom: $distance * 2px;
  }

  .pl-#{$distance*2} {
    padding-left: $distance * 2px;
  }

  .pr-#{$distance*2} {
    padding-right: $distance * 2px;
  }

  .m-#{$distance*2} {
    margin: $distance * 2px;
  }

  .mx-#{$distance*2} {
    margin-left: $distance * 2px;
    margin-right: $distance * 2px;
  }

  .my-#{$distance*2} {
    margin-top: $distance * 2px;
    margin-bottom: $distance * 2px;
  }

  .mt-#{$distance*2} {
    margin-top: $distance * 2px;
  }

  .mb-#{$distance*2} {
    margin-bottom: $distance * 2px;
  }

  .ml-#{$distance*2} {
    margin-left: $distance * 2px;
  }

  .mr-#{$distance*2} {
    margin-right: $distance * 2px;
  }

  .lh-#{$distance*2} {
    line-height: $distance * 2px;
  }
}

.lh {
  line-height: 1.4;
}

// 圆角
.round,
.round-xs {
  border-radius: 4px;
}

.round-sm {
  border-radius: 8px;
}

.round-md {
  border-radius: 12px;
}

.round-lg {
  border-radius: 16px;
}

.round-18 {
  border-radius: 18px;
}

.round-semicircle {
  border-radius: 40px;
}

.round-btn-full {
  border-radius: 42px;
}

.round-btn {
  border-radius: 32px;
}

.round-full {
  border-radius: 50%;
}

.round-bottom-16 {
  border-radius: 0 0 16px 16px;
}

.round-top-16 {
  border-radius: 16px 16px 0 0;
}

// border
.borderTop {
  border-top: 1px solid $hezi-color-line;
}

.borderBtm {
  border-bottom: 1px solid $hezi-color-line;
}

.line {
  width: 100%;
  border-top: 1px solid #d9d9d9;
  height: 1px;
}

// flex 布局
.flex {
  display: flex;
  flex-wrap: nowrap;
}

.flex-inline {
  display: inline-flex;
}

.flex.flex-col {
  flex-direction: column;
}

.flex.flex-wrap {
  flex-wrap: wrap;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-x-center {
  justify-content: center;
}

.flex-y-center {
  align-items: center;
}

.flex-y-end {
  align-items: flex-end;
}

.flex-between {
  justify-content: space-between;
}

.flex-x-end {
  justify-content: flex-end;
}

.flex-x-start {
  justify-content: flex-start;
}

.flex-y-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.full {
  box-sizing: border-box;
  width: 100%;
}

.flex-auto {
  flex: 1;
}

.tag {
  @extend .round-xs;

  display: inline-block;
  box-sizing: border-box;

  padding: 4px 8px;
  border: 1px solid currentColor;

  &.no-border {
    border: none;
  }
}

.lineOmit {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.divider-line {
  width: 100%;
  height: 1px;
  border-radius: 1px;
  background: #eeeeee;
}

.line-height-1-4 {
  line-height: 1.4;
}

// 设置全局为border-box盒模型
div {
  box-sizing: border-box;

  &::after,
  &::before {
    box-sizing: inherit;
  }
}

// a标签默认主题色
a {
  color: $hezi-color-primary;
  text-decoration: none;
}

textarea {
  margin: var(--textarea-inside-margin);
}

.pointer {
  cursor: pointer;
}

.ant-modal-body {
  line-height: 1.4;
}

.relative {
  position: relative;
}

.newbox-loading-message {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0 !important;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0);
  pointer-events: all !important;
}

.fit-img {
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit: cover;
}

.InfiniteScroll::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.ant-empty-description {
  color: #bdbdbd;
}

.popup-close {
  position: absolute;
  top: 14px;
  right: 16px;
}

.InfiniteScroll {
  // height: calc(100vh - 72px) !important;
  scrollbar-width: none;
}


// 超出一行显示省略号，和超出多行的不兼容
.ellipsis1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ellipsis2 {
  /*以下内容是文字隐藏所必须的*/
  display: -webkit-box;
  word-break: break-all;
  /*换行规则*/
  text-overflow: ellipsis;
  /*最后使用省略号显示*/
  overflow: hidden;
  /*超出部分隐藏*/
  // 此注释必须加
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.ellipsis3 {
  /*以下内容是文字隐藏所必须的*/
  display: -webkit-box;
  word-break: break-all;
  /*换行规则*/
  text-overflow: ellipsis;
  /*最后使用省略号显示*/
  overflow: hidden;
  /*超出部分隐藏*/
  // 此注释必须加
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.w-full {
  width: 100%;
}