// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.group-popup {
  position: relative;
  box-sizing: border-box;
  height: calc(100vh - 380px);
  border-radius: 24px 24px 0px 0px;
  position: relative;
  padding-bottom: 138px;

  .group-main {
    &-head {
      width: 100%;
      text-align: center;
      font-size: 24px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      line-height: 28px;
    }

    &-scroll {
      height: calc(100vh - 582px);
      box-sizing: border-box;
    }

    &-card {
      overflow-y: scroll;
      height: calc(100vh - 582px);

      .card-item {
        width: 100%;
        box-sizing: border-box;
        border-radius: 16px;
        background: #f6f6f6;

        &-head {
          width: 100%;
          height: 64px;
          border-bottom: 1px solid #eee;
          text-align: left;
          line-height: 64px;
        }

        &-con {
          &-img {
            width: 120px;
            height: 120px;
            border-radius: 12px;
          }

          &-cover {
            width: 120px;
            height: 120px;
            justify-content: space-between;
            align-content: space-between;

            .img {
              width: 58px;
              height: 58px;
              background: #eee;
            }

            .img1 {
              border-radius: 8px 0px 0px 0px;
            }

            .img2 {
              border-radius: 0px 8px 0px 0px;
            }

            .img3 {
              border-radius: 0px 0px 0px 8px;
            }

            .img4 {
              border-radius: 0px 0px 8px 0px;
            }
          }

          &-title {
            flex: 1;
            // width: 440px;
            height: 120px;
          }

          &-text {
            width: 100%;
          }

          &-label {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 16px;
          }

          .text-red {
            color: $hezi-color-primary;
          }
        }

        .icon-icon_select {
          width: 44px;
          height: 44px;
          background-size: 44px 44px;
          position: relative;
          color: #DDDDDD;

          &.checked {
            background-size: 44px 44px;
            color: red;
          }
        }
      }
    }

    &-btm {
      width: 100%;
      background: #fff;
      position: absolute;
      bottom: 0;
      left: 0;
      padding-top: 14px;
      box-sizing: border-box;
      justify-content: space-between;

      .button {
        width: 270px;
        padding: 0;
        height: 56px;
        line-height: 56px;
        border-radius: 100px;

        &.is-plain-primary {
          border-width: 2px;
          background-color: $hezi-background-white;
          border: 1px solid $hezi-color-primary;
          color: $hezi-color-primary;
        }
      }
    }
  }
}