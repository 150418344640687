// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

.good-img-box {
  display: flex;
  flex-direction: column;
}
.good-img.img {
  position: relative;
  z-index: 99;
  // height: 680px;
  width: 550px;
  margin-bottom: 13px;
  .img {
    width: 100%;
    &-content {
      // width: 126px;
      width: 550px;
      // height: 680px;
      height: 126px;
      overflow-x: scroll;
      overflow-y: hidden;
      scrollbar-width: none;
      display: flex;
    }
    .img-content::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
    }
    &-item {
      width: 126px;
      height: 126px;
      background: $hezi-background-gray;
      position: relative;
      overflow: hidden;
      flex: 0 0 auto;
      margin-right: 13px;
    }
    &-more {
      width: 44px;
      height: 44px;
      background: $hezi-background-white;
      border-radius: 50%;
      position: absolute;
      z-index: 99;
      &.top {
        top: 41px;
        left: -550px;
        right: 0;
        margin: auto;
        transform: rotate(-180deg);
      }
      &.bottom {
        bottom: 41px;
        left: 0;
        right: -550px;
        margin: auto;
        transform: rotate(0deg);
      }
    }
  }
}
