// 主题色
$hezi-color-primary: #FA2E2F;
$hezi-color-gray: #f6f6f6;
$hezi-color-line: #f0f0f0;
$hezi-color-primary-100: #fff2f1;
$hezi-color-gray-100: #f4f4f4;
$hezi-color-gray-200: #e7e9ec;
$hezi-color-gray-500: #1d1d1d;

// 文字色
$hezi-text-color: #000000;
$hezi-text-white: #fff;
$hezi-text-color-light: #FA2E2F;
$hezi-text-color-gray: #bdbdbd;
$hezi-text-color-500: #333333;

//icon
$hezi-gray-icon: #e4e4e4;

// 背景色
$hezi-background-white: #fff;
$hezi-background-gray: #eeeef1;
$hezi-background-plain: #ecf2ff;

// 首页小屏幕宽度
$phone1Width: 500px;

@media only screen and (max-width: 600px) {
  .box-floating {
    // 小屏幕隐藏底部浮层
    // display: none;
    span {
      font-size: 14px !important;
    }
    .floating-icon {
      width: 24px !important;
      height: 24px !important;
    }
    .floating-line {
      margin-left: 20px !important;
      margin-right: 20px !important;
    }
    // 为了不改之前的DOM
    .floating-mobile,
    .floating-icon {
      margin-bottom: 35px;
    }
    .floating-beian {
      position: absolute;
      right: auto;
      bottom: 25px;
      left: 20px;
    }
  }
}
